import * as React from "react"
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import "./styles.css";
import styled from 'styled-components';
import Hero from '../images/contact.jpg';
import { Helmet } from "react-helmet";
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const HeroImg = styled.img`
  height: 500px;
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

const TitleBox = styled.div`
    background: #e0e0e0;
    position: relative;
    top: -100px;
    max-width: 500px;
    height: 200px;
    opacity: 90%;

    @media screen and (max-width: 600px) {
      max-width: 75%;
      height: 150px;
    }
`;

const Heading = styled.h1`
  padding:60px 40px 40px 0;
  font-size:50px;
  text-align: center;
  font-family: 'Vollkorn', serif;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size:40px;
  }
`;

const FormSection = styled.section`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom:100px;

  .submit-feedback, .success {
    background: rgb(172, 193, 174);
    color: rgb(17, 37, 68);
    font-weight: bold;
  }

  @media screen and (max-width: 600px) {
    max-width: 400px;
  }
`;

const Submit = styled.button`
display: block;
margin: 40px auto 0px;
border: 3px solid rgb(17, 37, 68);
border-radius: 6px;
padding: 20px 50px;
background: white;
font-size: 18px;
font-weight: bold;
color: rgb(17, 37, 68);
font-family: Poppins, sans-serif;

&:hover {
  background: rgb(172, 193, 174);
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  padding: 10px 30px;
}
`;

const Form = styled.form`
max-width: 600px;
width: 100%;

@media screen and (max-width: 600px) {
    max-width: 400px;
  }

input[type="text" i], input[type="email" i] {
    height: 40px;
    border: 3px solid rgb(17, 37, 68);
    border-radius: 6px;
    background: white;
    font-size: 18px;
    width: 100%;
    margin: 10px;
    padding-left: 10px;
    font-family: Poppins, sans-serif;

    @media screen and (max-width: 600px) {
        width: 90%;
    }
}

textarea {
    height: 100px;
    border: 3px solid rgb(17, 37, 68);
    border-radius: 6px;
    background: white;
    font-size: 18px;
    width: 100%;
    margin: 10px;
    padding-left: 10px;
    font-family: Poppins, sans-serif;

    @media screen and (max-width: 600px) {
        width: 90%;
    }
}
`;
const HeadingSecondary = styled.h2`
font-family: Poppins, sans-serif;
color: rgb(17, 37, 68);
padding-left: 10px;
font-size: 30px;
`;

const Contact = () => {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm();

      const toastifySuccess = () => {
        toast('Form sent!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,  
          draggable: false,
          className: 'submit-feedback success',
          toastId: 'notifyToast'
        });
      };

    // Function called on submit that uses emailjs to send email of valid contact form
    const onSubmit = async (data) => {
        // Destrcture data object
        const { name, email, subject, message } = data;
        try {
          const templateParams = {
            name,
            email,
            subject,
            message
          };
    
          await emailjs.send(
           "service_69lvv0m", "template_bigg3zt", templateParams, "F3ibKSoNTCVfOgdDL"
          );
    
          reset();
          toastifySuccess();
        } catch (e) {
            console.log('here')
          console.log(e);
        }
      };

  return (
    <main>
      <Helmet>
      <title>Contact Us</title>
      <meta name="description" content="Dunning Currie Insurance Inc. is an independently owned Canadian Property &amp; Casualty premier insurance brokerage. " />
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta charSet="utf-8" />
      </Helmet>
      <Navigation />
      <div>
         <HeroImg src={Hero} alt="hero image" />
          <TitleBox><Heading>Contact Us</Heading></TitleBox>
      </div>
      <FormSection>
        <HeadingSecondary>How can we help?</HeadingSecondary>
      <Form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* Row 1 of form */}
                <div>
                  <div>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Please enter your name' },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Name'
                    ></input>
                    {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                  </div>
                  <div >
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      placeholder='Email address'
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>Please enter a valid email address</span>
                    )}
                  </div>
                </div>
                {/* Row 2 of form */}
                <div>
                  <div>
                    <input
                      type='text'
                      name='subject'
                      {...register('subject', {
                        required: { value: true, message: 'Please enter a subject' },
                        maxLength: {
                          value: 75,
                          message: 'Subject cannot exceed 75 characters'
                        }
                      })}
                      placeholder='Subject'
                    ></input>
                    {errors.subject && (
                      <span className='errorMessage'>{errors.subject.message}</span>
                    )}
                  </div>
                </div>
                {/* Row 3 of form */}
                <div>
                  <div>
                    <textarea
                      rows={3}
                      name='message'
                      {...register('message', {
                        required: true
                      })}
                      placeholder='Message'
                    ></textarea>
                    {errors.message && <span className='errorMessage'>Please enter a message</span>}
                  </div>
                </div>
                <Submit type='submit'>
                  Submit
                </Submit>
              </Form>
              <ToastContainer />
      </FormSection>
      <section>
      {/* <iframe src="https://snazzymaps.com/embed/414928" width="100%" height="400px"></iframe> */}
      <iframe src="https://snazzymaps.com/embed/414928" width="100%" height="400px"></iframe>
      </section>
      <Footer />
    </main>
  )
}

export default Contact

export const Head = () => <title>Contact Us</title>
